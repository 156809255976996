/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
// import "./layout.css"

import 'bootstrap/dist/css/bootstrap.min.css'
import "../assets/css/layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div style={{margin: `0 auto`, padding: `0`}}>
        <main>{children}</main>
        <footer>
          © {new Date().getFullYear()} Jesus Is Our Shield Worldwide Ministries
          <br/>
          <div>
            <a href="https://www.miraclehour.org" target="_blank">www.miraclehour.org</a>
            <br/>
            <a href="https://www.jioswm.com" target="_blank">www.jioswm.com</a>
            <br/>
            <a href="https://www.himala.ph" target="_blank">www.himala.ph</a>
          </div>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
