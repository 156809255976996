import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header className="header d-none">
    <div className="d-flex">
      <h1 className="logo" >
        <Link to="/">HIMALA.PH</Link>
      </h1>
      <img src="https://s3-ap-southeast-1.amazonaws.com/pblcfyls/jioswm/brand-icon-small.png" alt="Oras ng Himala - Jesus is our Shield Worldwide Ministries" />
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
